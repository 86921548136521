$black: #1d1d1d;
$grey: #909090;
$blue: #247ab7;
$orange: #ff3300;
$text-font: 'Libre Baskerville', serif;
$main-font: brandon-grotesque, 'Josefin Sans', sans-serif;

@font-face {
  font-family: brandon-grotesque;
  src: url('../../assets/brandon-grotesque/brandon_reg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: brandon-grotesque;
  src: url('../../assets/brandon-grotesque/brandon_med.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: brandon-grotesque;
  src: url('../../assets/brandon-grotesque/brandon_light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

body {
  font-family: $main-font;
  background-color: #fff;
  padding: 30px 0;
  color: $black;
}

body,
#container,
header,
main,
main > section,
.features-container,
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  max-width: 70vw;
  text-align: center;
  font-size: 15px;
  line-height: 1.4;
  font-family: $text-font;
  @media screen and (min-width: 767px) {
    max-width: unset;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  max-width: 80vw;
  font-weight: 300;
  text-align: center;
  @media screen and (min-width: 767px) {
    max-width: unset;
    font-weight: 400;
  }
}

div.cta {
  margin-top: 34px;
  @media screen and (min-width: 767px) {
    margin-top: 50px;
  }

  h3 {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 32px;
    color: $black;
  }

  button {
    font-family: $main-font;
    font-weight: 400;
    text-transform: uppercase;
  }
}

button {
  font-size: 15px;
  height: 50px;
  width: 80vw;
  font-weight: 600;
  background-color: $blue;
  color: white;
  border-radius: 50px;
  outline: none;
  border: none;
  cursor: pointer;

  @media screen and (min-width: 767px) {
    font-size: 18px;
    width: 300px;
  }
}

header {
  @media screen and (min-width: 767px) {
    max-width: 50vw;
    padding-top: 70px;
    &.connected {
      max-width: 800px;
    }
  }
  #noblr-logo {
    margin-bottom: 24px;
  }
  &.connected #noblr-logo {
    margin-bottom: 0;
  }

  em {
    font-style: normal;
    font-weight: 700;
  }

  .maindescription {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .presents {
    text-transform: uppercase;
    font-family: $main-font;
    font-size: 14px;
    font-weight: 300;
    @media screen and (min-width: 767px) {
      font-size: 20px;
    }
  }

  h1 {
    font-size: 30px;
    line-height: 1.4;
    margin-top: 16px;
    margin-bottom: 6px;
    font-weight: 300;
    @media screen and (min-width: 767px) {
      font-size: 50px;
      margin-bottom: 0;
      margin-top: 30px;
    }
  }
  &.connected h1 {
    margin-top: 10px;
    margin-bottom: 36px;
    @media screen and (min-width: 767px) {
      font-size: 30px;
      font-weight: 300;
      margin-bottom: 60px;
    }
  }
  h2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 767px) {
    &.connected h2 {
      font-size: 30px;
    }
  }
  p br {
    display: none;
  }
  @media screen and (min-width: 767px) {
    p {
      max-width: 50vw;
      font-size: 18px;
      line-height: 1.555;
      br {
        display: inline;
      }
    }
    &.connected p {
      max-width: 525px;
    }
  }

  &.connected:after {
    display: none;
  }
}

main {
  margin-bottom: 60px;
  @media screen and (min-width: 767px) {
    margin-bottom: 100px;
  }

  h2 {
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    @media screen and (min-width: 767px) {
      font-size: 30px;
    }

    .policy-check {
      color: $orange;
    }
    em {
      font-style: normal;
      text-decoration: underline;
    }

    @media screen and (min-width: 767px) {
      br {
        display: none;
      }
    }
  }

  .features-container {
    &:after {
      content: '';
      margin-top: 40px;
      margin-bottom: 40px;
      width: 12vh;
      border-bottom: 1px solid $grey;
      @media screen and (min-width: 767px) {
        margin-top: 100px;
        margin-bottom: 100px;
      }
    }
  }

  @media screen and (min-width: 767px) {
    section.features {
      flex-direction: row;
      display: flex;
      justify-content: space-evenly;
      margin-top: 12px;
    }
  }

  article {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 767px) {
      align-items: flex-start;
      flex-basis: 312px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        text-align: left;
      }
    }
    &:first {
      margin-top: 10px;
    }

    h2 {
      font-size: 18px;
      line-height: 1.1;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    &:before {
      content: '';
      height: 110px;
      margin-bottom: 18px;
      width: 105px;
      display: block;
    }

    &.potential:before {
      background: center bottom no-repeat url(../img/piggybank.svg);
    }

    &.rate:before {
      background: center bottom no-repeat url(../img/moneystack.svg);
    }

    &.missing:before {
      // this svg isn't centered
      margin-left: 26px;
      background: center bottom no-repeat url(../img/coverages.svg);
    }
  }

  .features-cta {
    margin-top: 0;
    text-align: center;

    @media screen and (min-width: 767px) {
      h3 {
        max-width: 50vw;
        font-size: 40px;
        margin: auto;
        br {
          display: none;
        }
      }
      h4 {
        max-width: 50vw;
        margin: auto;
      }
    }
  }

  .legal {
    h4 {
      font-family: $text-font;
      font-size: 15px;
      font-weight: 400px;
      margin: auto;
      line-height: 1.2em;
      @media screen and (min-width: 767px) {
        max-width: 50vw;
        text-align: justify;
      }
    }
  }
}
